<template>
  <v-app class="fill-height">
    <v-toolbar color="transparent" class="the-topbar">
      <v-layout
        row
        align-content-center
        justify-space-between
        style="margin-left: -4px"
      >
        <v-btn flat icon class="nav-sidebar-toggle" @click="sidebar = !sidebar">
          <CRIcon
            icon-name="menu"
            view-box="0 0 25 25"
            :width="25"
            :height="25"
          >
            menu
          </CRIcon>
        </v-btn>
        <router-link :to="{ name: 'home' }" replace class="nav-logo">
          <img
            src="@/assets/images/logo-coachrail.svg"
            style="
              position: relative;
              left: -10px;
              display: block;
              width: 130px;
              margin: 12px auto;
            "
          />
        </router-link>
        <v-spacer class="grow" />
        <v-menu bottom transition="slide-x-transition">
          <template #activator="{ on }">
            <v-btn id="topbar-button-profile" icon v-on="on">
              <v-avatar size="35">
                <img v-if="avatarLink" :src="avatarLink" />
                <CRIcon v-else icon-name="my-account" :width="32" :height="32">
                  my_account
                </CRIcon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile id="topbar-menu-logout-link" @click="logOut">
              <v-list-tile-title>
                <a>
                  <span class="action-text">Logout</span>
                </a>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-layout>
    </v-toolbar>
    <v-navigation-drawer
      id="the-side-bar"
      v-model="sidebar"
      class="crBlue elevation-0 the-side-bar"
      dark
      fixed
      clipped
      body-2
      :width="width"
    >
      <v-layout align-center style="margin: 15px 12px">
        <v-btn
          flat
          icon
          class="side-sidebar-toggle mr-1"
          @click="sidebar = !sidebar"
        >
          <CRIcon class="pb-0" view-box="0 0 25 25" :width="25" :height="25">
            menu
          </CRIcon>
        </v-btn>
        <router-link :to="{ name: 'home' }" replace class="nav-logo">
          <img
            src="@/assets/images/logo-coachrail-light.svg"
            style="display: block; width: 130px; margin: 13px auto"
          />
        </router-link>
      </v-layout>
    </v-navigation-drawer>
    <v-content>
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex shrink>
            <v-card color="transparent" flat max-width="600px">
              <v-img :src="require('@/assets/images/desktop_and_mobile.png')" />
              <v-card-title primary-title align-center class="justify-center">
                <h3 class="headline">
                  Your Coachrail Access has been restricted
                </h3>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  Please contact support@coachrail.com for any questions about
                  your CoachRail account or if you are having problems accessing
                  certain features.
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { baseUrl } from '@/utils/env'
import { authComputed } from '@/state/helpers'

export default {
  data() {
    return { sidebar: true }
  },
  computed: {
    ...authComputed,
    avatarLink() {
      const photos = this.currentUser?.userPhotoDTOs

      if (photos && photos.length > 0) {
        return `https://${baseUrl(null)}${photos[photos.length - 1].imagePath}`
      }
      return null
    },
  },
  mounted() {
    this.sidebar = this.$cr.breakpoint.sidebar
  },
  methods: {
    async logOut() {
      await this.$store.dispatch('auth/logOut')
    },
  },
}
</script>

<style lang="scss" scoped>
.the-topbar::v-deep {
  padding: 8px 14px;
  .v-toolbar__content {
    padding: 0 18px;
    overflow: hidden;

    .nav-sidebar-toggle {
      margin-right: 14px;
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 959px) {
  .the-topbar::v-deep {
    .v-toolbar__content {
      padding: 0 10px;
    }
  }
}

::v-deep .v-avatar img {
  object-fit: cover;
}

::v-deep i.v-icon.icon.icon-my-account.theme--light {
  font-size: 32px;
}

.text-center {
  text-align: center;
}
</style>
